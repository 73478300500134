const product_card = [
    {
        id: 1,
        product_name: "Rayes Alpha",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 350,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 2,
        product_name: "Rayes Z30",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 250,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 3,
        product_name: "Rayes X30",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 350,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 4,
        product_name: "Rayes Alpha",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 350,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 5,
        product_name: "Rayes Z30",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 250,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 6,
        product_name: "Rayes X30",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 350,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 7,
        product_name: "Rayes Alpha",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 350,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 8,
        product_name: "Rayes Z30",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 250,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    },
    {
        id: 9,
        product_name: "Rayes X30",
        description: "Hands-free, Hads-on Mushc Experience",
        price: 350,
        currency: "$",
        thumb: "https://i.ibb.co/PmqhB2h/1.png"
    }
]
export default product_card;